<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500" @keydown.esc="onClose">
      <v-card elevation="0">
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('shipment.parcels.parcel-scanning') }}
          <v-icon right dark large>
            mdi-barcode
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            {{ $t('shipment.parcels.scan-parcel-code') }}
          </v-row>
          <validation-observer ref="observer">
            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ numeric: true, min_value: 1 }"
                >
                  <v-text-field
                    ref="parcel"
                    v-model="scanned"
                    :label="$t('shipment.parcels.parcel-no')"
                    :error-messages="errors"
                    @keydown.esc="onClose"
                    @keydown.enter="onAdd"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </validation-observer>
          <v-row>
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              group-by="order"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
                'items-per-page-text': $t('table.per-page')
              }"
              :no-data-text="$t('table.no-data')"
            >
              <template #top>
                <v-toolbar flat>
                  <v-toolbar-title>{{
                    $t('shipment.parcels.added')
                  }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template #[`group.header`]="{ items, isOpen, toggle }">
                <th colspan="2" class="secondary">
                  <v-icon @click="toggle">
                    {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                  <span class="group white--text">{{ items[0].order }}</span>
                </th>
              </template>
            </v-data-table>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="onClose">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ShipmentService from '@/services/ShipmentService.js';

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    shipment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: this.value,
      scanned: '',
      items: [],
      focusInterval: null
    };
  },

  computed: {
    headers() {
      return [
        {
          text: '#Id',
          value: 'id',
          sortable: true
        },
        {
          text: 'Order',
          value: 'order',
          sortable: true
        },
        {
          text: 'Pcs',
          value: 'pcs',
          sortable: true
        },
        {
          text: 'Box Type',
          value: 'boxType',
          sortable: true
        }
      ];
    }
  },

  watch: {
    value(value) {
      this.dialog = value;
      this.reset();

      if (value) {
        if (!this.focusInterval) {
          this.focusInterval = setInterval(this.focus, 500);
        }
      } else {
        clearInterval(this.focusInterval);
        this.focusInterval = null;
      }
    }
  },

  methods: {
    reset() {
      this.scanned = '';
      this.items = [];
    },
    async onAdd() {
      console.log('add:', this.scanned);
      const result = await this.$refs.observer.validate();
      if (!result) {
        return;
      }
      try {
        const id = parseInt(this.scanned.trim());
        const rsp = await ShipmentService.appendParcels(this.shipment.id, [id]);
        if (!rsp.data?.length || rsp.data[0]?.id != id) {
          throw `Parcel '${id}' not added`;
        }
        const added = rsp.data[0];
        this.items.push(added);
        console.log('parcel added:', added);

        const shipment = await ShipmentService.getShipment(this.shipment.id);
        this.$root.$emit('shipment_updated', shipment);

        this.$emit('added', id);
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      }
      this.scanned = '';
    },
    onClose() {
      this.$emit('input', false);
    },
    focus() {
      console.log('focus:', this.$refs.parcel);
      this.$refs.parcel.focus();
    }
  }
};
</script>
